import { PER_PAGE_DEFAULT_EMPLOYEES } from "constants";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useGetListEmployee } from "./useEmployee";

const useSearchEmployee = (
  metadata = {
    is_required_all: null,
    is_open: false,
  },
  setValue = null
) => {
  const allCompany = useMemo(() => !!metadata?.is_required_all, [
    metadata?.is_required_all,
  ]);
  const isOpen = useMemo(() => !!metadata?.is_open, [metadata?.is_open]);
  const [paramsListEmployee, setParamsListEmployee] = useState({
    sortType: null,
    no_data: 0,
    page: 1,
    limit: PER_PAGE_DEFAULT_EMPLOYEES,
    isPaging: 0,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50,
  });
  const [exceptionList, setExceptionList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [selectedListState, setSelectedListState] = useState([]);
  const [exceptionListState, setExceptionListState] = useState([]);
  const { data, isLoading } = useGetListEmployee(paramsListEmployee);

  const dataEmployees = useMemo(() => {
    return data?.result?.data ?? [];
  }, [data]);

  useEffect(() => {
    setExceptionListState(exceptionList);
    setSelectedListState(selectedList);
  }, [selectedList, exceptionList, isOpen]);

  const checkAllCondition = () => {
    if (
      (!Array.isArray(exceptionListState) &&
        !Array.isArray(selectedListState)) ||
      !Array.isArray(dataEmployees) ||
      !dataEmployees.length
    ) {
      return false;
    }
    if (allCompany) {
      return (
        exceptionListState
          .map((item) =>
            dataEmployees.find((employeeItem) => item.id === employeeItem.id)
          )
          .filter((item) => item !== undefined).length === 0
      );
    }

    return (
      dataEmployees
        .map((employeeItem) =>
          selectedListState?.find((item) => item.id === employeeItem.id)
        )
        .filter((item) => item !== undefined).length === dataEmployees.length
    );
  };

  const onChangeSelectAll = (isChecked) => {
    if (paramsListEmployee.isPaging && !isChecked && setValue) {
      allCompany ? setValue("is_required_all", false) : setSelectedList([]);
      return;
    }
    if (isChecked) {
      if (allCompany) {
        setExceptionListState((prevState) =>
          prevState.filter(
            (item) =>
              !dataEmployees.some((employeeItem) => item.id === employeeItem.id)
          )
        );
      }

      setSelectedListState((prevState) =>
        {
          return prevState.concat(
            dataEmployees
              .filter(
                (employeeItem) =>
                  !prevState.some(
                    (prevStateItem) => prevStateItem.id === employeeItem.id
                  ) && employeeItem.work_status !== "退職者" && employeeItem.work_status !== "内定者" // not selected employee work status !=1,2
              )
              .map((item) => ({ name: item.name, id: item.id }))
          )
        }
      );
      return;
    }

    if (allCompany) {
      setExceptionListState((prevState) =>
        prevState.concat(
          dataEmployees
            .filter(
              (employeeItem) =>
                !prevState.some(
                  (prevStateItem) => prevStateItem.id === employeeItem.id
                )
            )
            .map((item) => ({ name: item.name, id: item.id }))
        )
      );
    }

    setSelectedListState([]); // set selected list when uncheck all

    // setSelectedListState((prevState) =>
    //   {
    //     return prevState.filter(
    //       (item) =>
    //         !dataEmployees.some((employeeItem) => item.id === employeeItem.id)
    //     )
    //   }
    // );
  };

  const checkSelected = (item) => {
    // not check employees not working , work_status !== 1,2
    if (item.work_status === "退職者" || item.work_status === "内定者") {
      return false;
    } 

    if (allCompany) {
      return !exceptionListState?.find(
        (exceptionItem) => exceptionItem.id === item.id
      );
    }

    return !!selectedListState?.find((selected) => selected.id === item.id);
  };

  const handleCheckboxChange = (event, item) => {
    if (allCompany) {
      if (!event.target.checked) {
        setExceptionListState([
          ...exceptionListState,
          { id: item.id, name: item.name },
        ]);
      } else {
        setExceptionListState(
          exceptionListState?.filter((i) => i.id !== item.id)
        );
      }
      return;
    }

    if (event.target.checked) {
      setSelectedListState([
        ...selectedListState,
        { id: item.id, name: item.name },
      ]);
    } else {
      setSelectedListState(selectedListState?.filter((i) => i.id !== item.id));
    }
  };

  const onSubmit = (data) => {
    // get data when get default
    if (data && data.clearSearch) {
      setParamsListEmployee({
        ...paramsListEmployee,
        id_exception_list: null,
        id_selected_list: null,
        no_data: metadata?.is_required_all ? 0 : 1,
      });
      return;
    }

    const paramsData = {
      name: data.name,
      from: data.from ? moment(data.from).format("YYYY-MM-DD") : "",
      to: data.to ? moment(data.to).format("YYYY-MM-DD") : "",
      work_status: data.work_status,
      role_1: data.role_1,
      job_code: data.job_code,
      department: data.department,
      role_2: data.role_2,
      position_code: data.position_code,
      employment_type_code: data.employment_type_code,
      business: data.business,
      code: data.code,
      id_selected_list: null,
      id_exception_list: null,
      department_2_code: data.department_2_code,
      department_3_code: data.department_3_code,
      department_4_code: data.department_4_code,
      department_5_code: data.department_5_code,
      employee_group: data.employee_group ?? null,
      no_data: 0,
    };
    if (Object.values(data).find((item) => !!item)) {
      setParamsListEmployee({
        ...paramsListEmployee,
        ...paramsData,
        isPaging: 0,
        page: 1,
      });
      setPagination({
        ...pagination,
        page: 1,
      });
      return;
    }

    if (
      metadata?.is_required_all &&
      exceptionListState &&
      exceptionListState.length
    ) {
      setParamsListEmployee({
        ...paramsListEmployee,
        ...paramsData,
        id_exception_list: JSON.stringify(
          exceptionListState.map((item) => item.id)
        ),
        id_selected_list: null,
        no_data: 0,
        isPaging: 1,
      });

      return;
    }

    if (selectedListState && selectedListState.length) {
      setParamsListEmployee({
        ...paramsListEmployee,
        ...paramsData,
        id_selected_list: JSON.stringify(
          selectedListState.map((item) => item.id)
        ),
        id_exception_list: null,
        no_data: 0,
        isPaging: 1,
      });

      return;
    }

    setParamsListEmployee({
      ...paramsListEmployee,
      ...paramsData,
      id_exception_list: null,
      id_selected_list: null,
      no_data: metadata?.is_required_all ? 0 : 1,
    });
  };

  return {
    isLoading,
    responseEmployees: data,
    dataEmployees,
    paramsListEmployee,
    setParamsListEmployee,
    pagination,
    setPagination,
    onSubmit,
    selectedListState,
    setSelectedListState,
    exceptionListState,
    setExceptionListState,
    checkAllCondition,
    onChangeSelectAll,
    exceptionList,
    setExceptionList,
    selectedList,
    setSelectedList,
    checkSelected,
    handleCheckboxChange,
  };
};

export default useSearchEmployee;
