import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { t } from "i18next";
import React from "react";
import SecondaryButton from "components/Buttons/SecondaryButton";
const ModalConfirmAssign = ({
  isOpen,
  content,
  onSubmit,
  onClose,
  titleBtnClose = "close",
  titleBtnOk = "ok",
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={"10px"} paddingLeft={"20px"}>{content}</ModalBody>
        <ModalFooter justifyContent={"center"}>
          <SecondaryButton
            text={titleBtnClose}
            onClick={onClose}
          />
          <PrimaryButton
            text={titleBtnOk}
            styles={{
              ml: "15px",
            }}
            onClick={onSubmit}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalConfirmAssign;
