import { Box, Flex, Grid, GridItem, Select, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import GreenButton from "components/Buttons/GreenButton";
import MainColorButton from "components/Buttons/MainColorButton";
import PrimaryButton from "components/Buttons/PrimaryButton";
import EDateRangePicker from "components/EDateRangePicker";
import EInput from "components/EInput";
import ESelect from "components/ESelect";
import FullPageLoader from "components/Loading/FullPageLoading";
import ModalConfirm from "components/modals/ModalConfirm";
import { FORMAT_DATE_PLACEHOLDER } from "constants";
import {
  SEARCH_EMPLOYEE_TYPE_DATE_PICKER,
  SEARCH_EMPLOYEE_TYPE_SELECT,
  SEARCH_EMPLOYEE_TYPE_INPUT,
  EMPLOYEE_GROUPS,
  EMPLOYEE_GROUP_BUSSINESS,
  EMPLOYEE_GROUP_OTHER_BUSSINESS,
  EMPLOYEE_GROUP_REGISTERED_EMPLOYMENT_TYPE_CODES,
  EMPLOYEE_GROUP_BUSSINESS_EMPLOYMENT_TYPE_CODES,
  EMPLOYEE_GROUP_BUSSINESS_JOB_CODES,
  EMPLOYEE_GROUP_OTHER_BUSSINESS_EMPLOYMENT_TYPE_CODES,
  EMPLOYEE_GROUP_OTHER_BUSSINESS_JOB_CODES
} from "constants";
import { useRandomAssignCourse } from "hooks/useEmployee";
import { useModalState } from "hooks/useModalState";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { PERSONAL_TARGET_GROUPS } from "constants";

const SearchEmployeeForm = (props) => {
  const {
    inputList,
    methods,
    col,
    submitAndClose,
    handleSubmit,
    enableTargetGroup,
    selectedData,
    isModal,
    formKeys = [],
    handleResetListCourse,
    handleSearchEmployeeDefault,
    isHideSubmitAndClose=false,
    onChangeSelectAll,
  } = props;
  const history = useHistory()
  const [groupValue, setGroupValue] = useState(null);
  const [enableSearch, setEnableSearch] = useState(
    enableTargetGroup ? false : true
  );
  const [isClearParamSearch, setIsClearParamSearch] = useState(false);
  const [isClearListEmployee, setIsClearListEmployee] = useState(false);

  const mutationRandomAssignCourse = useRandomAssignCourse();

  const { modalState, open, close, closeAll } = useModalState({
    modalConfirmAssign: false,
  })

  const handleSetGroup = () => {
    methods.setValue("employee_group", groupValue);
    setEnableSearch(!!groupValue);

    // clear from key when click change employee_group value
    formKeys.map((formKey) => {
      if (formKey === "employee_group") return;
      methods.setValue(formKey, "");
    });

    setIsClearParamSearch(false);
    setIsClearListEmployee(false);

    // when selection employee_group == default is null
    if (isClearListEmployee && isClearParamSearch) {
      handleSearchEmployeeDefault();
      return;
    }

    if (isClearParamSearch) {
      handleResetListCourse();
      handleSubmit({})
      return
    }

    handleSubmit();
  };

  /**
   * Handle data select for personal training
   *
   * @param {*} inputName 
   * @param {*} data 
   * @returns 
   */
  const handleDataSelect = (inputName, data) => {
    if (!groupValue) {
      return data;
    }
    if (groupValue === EMPLOYEE_GROUP_BUSSINESS) {
      if (inputName === 'employment_type_code') {
        return [{ id: "", name: "" }, ...data.filter((item) => EMPLOYEE_GROUP_BUSSINESS_EMPLOYMENT_TYPE_CODES.includes(item.code))];
      } else if (inputName === 'job_code') {
        return [{ id: "", name: "" }, ...data.filter((item) => EMPLOYEE_GROUP_BUSSINESS_JOB_CODES.includes(item.code))];
      } else {
        return data;
      }
    } else if (groupValue === EMPLOYEE_GROUP_OTHER_BUSSINESS) {
      if (inputName === 'employment_type_code') {
        return [{ id: "", name: "" }, ...data.filter((item) => EMPLOYEE_GROUP_OTHER_BUSSINESS_EMPLOYMENT_TYPE_CODES.includes(item.code))];
      } else if (inputName === 'job_code') {
        return [{ id: "", name: "" }, ...data.filter((item) => EMPLOYEE_GROUP_OTHER_BUSSINESS_JOB_CODES.includes(item.code))];
      } else {
        return data;
      }
    } else {
      if (inputName === 'employment_type_code') {
        return [{ id: "", name: "" }, ...data.filter((item) => EMPLOYEE_GROUP_REGISTERED_EMPLOYMENT_TYPE_CODES.includes(item.code))];
      }
      return data;
    }
  };

  const handleRandomAssign = () => {
    close('modalConfirmAssign')
    mutationRandomAssignCourse.mutate({
      employee_ids: selectedData.map((item) => item.id),
      callback: handleResetListCourse,
      group_type: groupValue,
      target_group_id: groupValue,
    });
  };

  const handleSetGroupSelection = (e) => {
    // when change employee_group
    if ((groupValue !== e?.target?.value)) {
      setIsClearParamSearch(true);
    };

    // clear data search when change employee_group
    if ((groupValue && !e?.target?.value)) {
      setIsClearListEmployee(true);
    };

    setGroupValue(e?.target?.value ?? null)
  };

  useEffect(() => {
    if (mutationRandomAssignCourse.isLoading) {
      setTimeout(() => {
        onChangeSelectAll(true);
      }, 500)
    }
  }, [mutationRandomAssignCourse.isLoading]);

  return (
    <form onSubmit={handleSubmit}>
      {mutationRandomAssignCourse.isLoading && <FullPageLoader />}
      {enableTargetGroup && (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: `repeat(${col}, 1fr)`,
          }}
          gap="10px"
          mb="30px"
        >
          <GridItem colSpan={2}>
            <Flex pl="50px">
              <Box w={"100px"} textAlign={"right"} marginRight={"5px"} marginTop={"4px"}>
                <Text fontSize={"14px"}>
                  {t("pages.random_assign_course.target_group")}
                </Text>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Select
                  style={{ height: "30px", fontSize: "14px" }}
                  placeholder={t("common.option.placeholder")}
                  onChange={handleSetGroupSelection}
                >
                  {PERSONAL_TARGET_GROUPS.map((group) => (
                    <option value={group.id} key={group.id}>
                      {group.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box ml="10px">
                <PrimaryButton
                  text={t("common.confirm")}
                  onClick={handleSetGroup}
                />
              </Box>
            </Flex>
          </GridItem>
          <GridItem textAlign="center">
            <Flex justifyContent="center">
              <MainColorButton
                text={t("pages.random_assign_course.allocate_course")}
                onClick={() => open('modalConfirmAssign')}
                disabled={!selectedData?.length}
              />
              <Box ml="10px">
                <PrimaryButton
                  text={t("pages.courses.create_new")}
                  onClick={() => history.push('individual-training/courses/create')}
                />
              </Box>
            </Flex>
          </GridItem>
        </Grid>
      )}

      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: `repeat(${col}, 1fr)` }}
        gap="10px"
      >
        {inputList.map((inputItem, index) => {
          switch (inputItem.type) {
            case SEARCH_EMPLOYEE_TYPE_INPUT:
              return (
                <SearchInput
                  label={inputItem.label}
                  name={inputItem.name}
                  control={methods.control}
                  disabled={!enableSearch}
                  key={index}
                />
              );
            case SEARCH_EMPLOYEE_TYPE_SELECT:
              return (
                <SearchSelect
                  label={inputItem.label}
                  name={inputItem.name}
                  control={methods.control}
                  data={
                    isModal
                      ? inputItem.data
                      : handleDataSelect(inputItem.name, inputItem.data)
                  }
                  disabled={!enableSearch}
                  key={index}
                />
              );
            case SEARCH_EMPLOYEE_TYPE_DATE_PICKER:
              return (
                <SearchDateRange
                  label={inputItem.label}
                  nameTo={inputItem.nameTo}
                  nameFrom={inputItem.nameFrom}
                  methods={methods}
                  disabled={!enableSearch}
                  key={index}
                />
              );
            default:
              return <></>;
          }
        })}
      </Grid>
      <Flex alignItems={"center"} mt={"10px"} justifyContent={"end"}>
        {!isHideSubmitAndClose && (
          <Box mr={"10px"}>
            <PrimaryButton
              disabled={!enableSearch}
              text={t("pages.course_form.add_member")}
              onClick={submitAndClose}
            />
          </Box>
        )}

        <Box>
          <GreenButton disabled={!enableSearch} text={t("pages.course_form.search")} type="submit" />
        </Box>

        <ModalConfirm
          isOpen={modalState.modalConfirmAssign}
          content={t("pages.random_assign_course.title_modal")}
          onClose={() => close('modalConfirmAssign')}
          onSubmit={handleRandomAssign}
          titleBtnClose={t("pages.random_assign_course.btn_cancel")}
          titleBtnOk={t("pages.random_assign_course.btn_ok")}
        />
      </Flex>
    </form>
  );
};

const SearchInput = (props) => {
  const { label, name, placeholder, control, disabled } = props;

  return (
    <GridItem key={name}>
      <Flex>
        <Box w={"100px"} textAlign={"right"} marginRight={"5px"}>
          <Text fontSize={"14px"}>{t(label)}</Text>
        </Box>
        <Box sx={{ flex: 1 }}>
          <EInput
            name={name}
            placeholder={placeholder ?? ""}
            control={control}
            style={{ h: "30px" }}
            disabled={disabled}
          />
        </Box>
      </Flex>
    </GridItem>
  );
};

const SearchSelect = (props) => {
  const { label, name, placeholder, data, control, disabled } = props;

  return (
    <GridItem key={name}>
      <Flex>
        <Box w={"100px"} textAlign={"right"} marginRight={"5px"}>
          <Text fontSize={"14px"}>{t(label)}</Text>
        </Box>
        <Box sx={{ flex: 1 }}>
          <ESelect
            style={{ h: "30px" }}
            name={name}
            placeholder={placeholder ?? ""}
            control={control}
            dataSelect={data}
            disabled={disabled}
          />
        </Box>
      </Flex>
    </GridItem>
  );
};

const SearchDateRange = (props) => {
  const { label, nameTo, nameFrom, methods, disabled } = props;

  return (
    <GridItem key={`${nameFrom}${nameTo}`} colSpan={2}>
      <Flex>
        <Box w={"100px"} textAlign={"right"} marginRight={"5px"}>
          <Text fontSize={"14px"}>{t(label)}</Text>
        </Box>
        <Flex sx={{ flex: 1 }}>
          <Box sx={{ flex: 1 }}>
            <EDateRangePicker
              style={{ h: "30px" }}
              disabled={disabled}
              placeholder={FORMAT_DATE_PLACEHOLDER}
              dateFormat="yyyy-MM-dd"
              setValue={methods.setValue}
              control={methods.control}
              name={nameFrom}
              getValues={methods.getValues}
              reset={methods.reset}
            />
          </Box>
          <Box w={"115px"} textAlign={"center"}>
            <Text>~</Text>
          </Box>
          <Box sx={{ flex: 1 }}>
            <EDateRangePicker
              style={{ h: "30px" }}
              disabled={disabled}
              placeholder={FORMAT_DATE_PLACEHOLDER}
              dateFormat="yyyy-MM-dd"
              setValue={methods.setValue}
              control={methods.control}
              name={nameTo}
              getValues={methods.getValues}
              reset={methods.reset}
            />
          </Box>
        </Flex>
      </Flex>
    </GridItem>
  );
};

export default SearchEmployeeForm;
