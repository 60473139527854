import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { t } from "i18next";
import * as yup from "yup";
import moment from "moment";
import { HSeparator } from "components/Separator/Separator";
import SearchEmployeeForm from "components/SearchEmployee/SearchEmployeeForm";
import {
  FIELD_SEARCH_EMPLOYEE_CODE,
  FIELD_SEARCH_EMPLOYEE_NAME,
  FIELD_SEARCH_EMPLOYEE_WORK_STATUS,
  FIELD_SEARCH_EMPLOYEE_TYPE,
  FIELD_SEARCH_EMPLOYEE_HIRING_DATE,
  FIELD_SEARCH_EMPLOYEE_ROLE_1,
  FIELD_SEARCH_EMPLOYEE_ROLE_2,
  FIELD_SEARCH_EMPLOYEE_JOB,
  FIELD_SEARCH_EMPLOYEE_BUSSINESS,
  FIELD_SEARCH_EMPLOYEE_DEPARTMENT,
  FIELD_SEARCH_EMPLOYEE_POSITION,
  FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_2,
  FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_3,
  FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_4,
  FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_5
} from "constants";
import SearchEmployeeResult from "components/SearchEmployee/SearchEmployeeResult";
import { useMasterData } from "hooks/useMasterData";
import { customSearchEmployeeInput } from "utils";

const ModalEmployees = ({
  isOpen,
  onClose,
  dataEmployees,
  totalCount,
  paramsListEmployee,
  setParamsListEmployee,
  setSelectedList,
  allCompany,
  setExceptionList,
  isLoading,
  pagination,
  setPagination,
  onSubmit,
  selectedListState,
  setSelectedListState,
  exceptionListState,
  setExceptionListState,
  checkAllCondition,
  onChangeSelectAll,
  checkSelected,
  handleCheckboxChange
}) => {
  const schema = yup.object().shape({
    from: yup.string().nullable(),
    to: yup
      .string()
      .nullable()
      .test("error", t("messages.date_end"), (value, context) => {
        return !(value && new Date(value) < new Date(context.parent.from));
      }),
  });

  const methods = useForm({
    defaultValues: {
      name: "",
      from: "",
      to: "",
      work_status: "",
      role_1: "",
      job_code: "",
      department: "",
      role_2: "",
      position_code: "",
      employment_type_code: "",
      business: "",
      code: "",
    },
    resolver: yupResolver(schema),
  });

  const { data: masterData } = useMasterData();

  const closeModal = () => {
    methods.reset();
    resetFormValues(["from", "to"]);
    onSubmit({});
    setExceptionListState([]);
    setSelectedListState([]);
    onClose();
  };

  const resetFormValues = (fields) => {
    fields.forEach((field) => {
      methods.setValue(field, null);
    });
  };

  const submitAndClose = () => {
    methods.reset();
    onSubmit({});
    setExceptionList(exceptionListState);
    setSelectedList(selectedListState);
    onClose();
  };

  useEffect(() => {
    setSelectedListState([]);
    setExceptionListState([]);
  }, [allCompany]);

  useEffect(() => {
    if (!isOpen) {
      onSubmit({});
    }
  }, [selectedListState, exceptionListState]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("pages.course_form.list_member")}</ModalHeader>
        <ModalCloseButton />
        <HSeparator />
        <ModalBody>
          <SearchEmployeeForm
            methods={methods}
            inputList={[
              FIELD_SEARCH_EMPLOYEE_CODE,
              FIELD_SEARCH_EMPLOYEE_NAME,
              FIELD_SEARCH_EMPLOYEE_WORK_STATUS,
              customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_TYPE, {
                data: [{ id: "", name: "" }].concat(
                  masterData?.employment_types ?? []
                ),
              }),
              FIELD_SEARCH_EMPLOYEE_HIRING_DATE,
              FIELD_SEARCH_EMPLOYEE_ROLE_1,
              FIELD_SEARCH_EMPLOYEE_ROLE_2,
              customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_JOB, {
                label: "pages.course_form.job_type",
                data: [{ id: "", name: "" }].concat(masterData?.jobs ?? []),
              }),
              customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_2, {
                data: [{ id: "", name: "" }].concat(
                  masterData?.deparment_layer_2 ?? []
                ),
              }),
              customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_3, {
                data: [{ id: "", name: "" }].concat(
                  masterData?.deparment_layer_3 ?? []
                ),
              }),
              customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_4, {
                data: [{ id: "", name: "" }].concat(
                  masterData?.deparment_layer_4 ?? []
                ),
              }),
              customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_5, {
                data: [{ id: "", name: "" }].concat(
                  masterData?.deparment_layer_5 ?? []
                ),
              }),
            ]}
            col={3}
            submitAndClose={submitAndClose}
            handleSubmit={methods.handleSubmit(onSubmit)}
            isModal
          />
          <SearchEmployeeResult
            dataEmployees={dataEmployees}
            paramsListEmployee={paramsListEmployee}
            setParamsListEmployee={setParamsListEmployee}
            pagination={pagination}
            setPagination={setPagination}
            isLoading={isLoading}
            checkAllCondition={checkAllCondition}
            checkSelected={checkSelected}
            handleCheckboxChange={handleCheckboxChange}
            onChangeSelectAll={onChangeSelectAll}
            totalCount={totalCount}
            maxHeigh="full"
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalEmployees;
